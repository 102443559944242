<template>
  <ConfirmDialog
    :value.sync="show"
    maxWidth="800px"
    title="Rejestr zmian"
    positiveButtonText=""
    :loading="loading"
    :compact="false"
    negativeButtonColor="primary"
    showCloseBtn
  >
    <v-layout column align-center mb-2 slot="header" style="width: 100%">
      <DefaultLabel>{{ name }}</DefaultLabel>
    </v-layout>

    <v-layout column slot="content">
      <v-layout class="popup-content" column>
        <v-layout
          column
          class="mx-4"
          v-if="fields != undefined && fields.length > 0"
        >
          <FieldWrapperDelegate
            v-for="(recField, index) in fields"
            :key="recField.id"
            :field="recField"
            :class="['my-2', index != 0 && 'ml-12']"
            hideHeader
            hideEdit
            disabled
            sessionField
          ></FieldWrapperDelegate>
        </v-layout>
        <v-layout
          v-if="fields != undefined && fields.length == 0 && !loading"
          class="text-center"
          align-center
          justify-center
        >
          <div class="text-subtitle-2 error--text font-weight-bold">
            Brak wpisów
          </div>
        </v-layout>
      </v-layout>
    </v-layout>
  </ConfirmDialog>
</template>

<script>
import CRUDMixin from "@/mixins/CRUDMixin";
import PatientService from "@/services/patient.service";

export default {
  mixins: [CRUDMixin],
  props: {
    value: {},
    field: {},
    patient: {},
    session: {},
    owner: {},
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fields: undefined,
      totalItems: 0,
      pagination: {
        rowsPerPage: 5,
        page: 1,
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  components: {
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    FieldWrapperDelegate: () =>
      import("@/components/delegates/patientcard/FieldWrapperDelegate"),
  },
  methods: {
    fetchData() {
      this.beforeRequest();
      PatientService.getHistoryField({
        name: this.field.name,
        session: this.session.id,
        options: this.pagination,
        patient: this.patient ? this.patient.id : undefined,
        owner: this.owner ? this.owner.id : undefined,
        field: 1
      })
        .then((reply) => {
          this.fields = reply;
          this.reset();
        })
        .catch((x) => this.handleError(x));
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped lang="css">
.bg {
  border-radius: 15px;
  background: white;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
}
.popup-content {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
</style>